<template>
  <b-sidebar
    id="opened-modal-form"
    :visible="openedModalForm"
    bg-variant="white"
    sidebar-class="sidebar-lg"
    shadow
    backdrop
    no-header
    right
    @change="(val) => $emit('update:opened-modal-form', val)"
  >
    <template>
      <!-- Header -->
      <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
        <h5
          v-if="item.id === undefined"
          class="mb-0"
        >
          {{ $t('general.create') }}
        </h5>
        <h5
          v-else
          class="mb-0"
        >
          {{ $t('general.edit') }}
        </h5>

        <feather-icon
          class="ml-1 cursor-pointer"
          icon="XIcon"
          size="16"
          @click="hide"
        />
      </div>

      <!-- BODY -->
      <!-- Form -->
      <b-form class="p-2">
        <b-tabs>
          <b-tab
            v-for="language in languages"
            :key="language.code"
          >
            <template #title>
              <b-img
                :src="require('@/assets/images/flags/' + language.code + '.svg')"
                height="16"
                width="16"
                class="mr-1"
              />
              <span class="d-none d-sm-inline">{{ language.title }}</span>
            </template>
            <b-row>
              <b-col
                cols="24"
                md="12"
              >
                <b-form-group
                  :label="$t('form.title.label')"
                  :label-for="'title.' + language.code"
                >
                  <b-form-input
                    :id="'title.' + language.code"
                    v-model="item.title[language.code]"
                    :state="errors && errors['title.' + language.code] ? false : null"
                  />
                  <b-form-invalid-feedback v-if="errors && errors['title.' + language.code]">
                    {{ errors['title.' + language.code][0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </b-col>
            </b-row>
          </b-tab>
        </b-tabs>
        <b-row>
          <b-col cols="12">
            <b-form-group
              :label="$t('form.href.label')"
              :label-for="'href'"
            >
              <b-form-input
                :id="'href'"
                v-model="item.href"
                :state="errors && errors.href ? false : null"
              />
              <b-form-invalid-feedback v-if="errors && errors.href">
                {{ errors.href[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </b-col>
          <b-col cols="12">
            <b-form-group
              :label="$t('form.parent_id.label')"
              label-for="parent_id"
              :state="errors && errors.parent_id ? false : null"
            >
              <treeselect
                id="parent_id"
                v-model="item.parent_id"
                :multiple="false"
                :options="items"
                :normalizer="normalizer"
              />
              <b-form-invalid-feedback v-if="errors && errors.parent_id">
                {{ errors.parent_id[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </b-col>
          <b-col
            cols="12"
            md="6"
          >
            <b-form-group :label="$t('form.icon.label')">
              <b-media no-body>
                <b-media-aside>
                  <b-link>
                    <b-img
                      ref="previewEl"
                      rounded
                      :src="$options.filters.mediaUrl(item, 'avatar')"
                      height="80"
                    />
                  </b-link>
                  <!--/ avatar -->

                  <!-- reset -->
                  <b-button
                    v-if="item.avatar"
                    v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                    variant="outline-secondary"
                    size="sm"
                    class="mb-75 mr-75"
                    @click="selectedAvatar(null)"
                  >
                    {{ $t('general.reset') }}
                  </b-button>
                  <!--/ reset -->
                </b-media-aside>

                <b-media-body class="mt-75 ml-75">
                  <InitFileManager
                    field="avatar"
                    type="navigation_item_avatar"
                    :multiple="false"
                    @fm-selected="selectedAvatar"
                  />

                  <b-row
                    v-if="errors && errors.avatar"
                  >
                    <b-col cols="12">
                      <b-form-invalid-feedback :state="false">
                        {{ errors.avatar[0] }}
                      </b-form-invalid-feedback>
                    </b-col>
                  </b-row>
                  <b-row
                    v-if="errors && errors['avatar.type']"
                  >
                    <b-col cols="12">
                      <b-form-invalid-feedback :state="false">
                        {{ errors['avatar.type'][0] }}
                      </b-form-invalid-feedback>
                    </b-col>
                  </b-row>

                </b-media-body>
              </b-media>
            </b-form-group>
          </b-col>
          <b-col
            cols="12"
            md="6"
          >
            <b-form-group
              :label="$t('form.position.label')"
              label-for="position"
            >
              <b-form-input
                id="position"
                v-model="item.position"
                :state="errors && errors.position ? false : null"
              />
              <b-form-invalid-feedback v-if="errors && errors.position">
                {{ errors.position[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
            <b-form-group
              :label="$t('form.blank.label')"
              label-for="blank"
            >
              <b-form-checkbox
                id="blank"
                v-model="item.blank"
              />
              <b-form-invalid-feedback v-if="errors && errors.blank">
                {{ errors.blank[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </b-col>
        </b-row>

        <!-- Form Actions -->
        <div class="d-flex mt-2">
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            class="mr-2"
            @click="onSubmit"
          >
            {{ $t('general.save') }}
          </b-button>
          <b-button
            v-ripple.400="'rgba(186, 191, 199, 0.15)'"
            type="button"
            variant="outline-secondary"
            @click="hide"
          >
            {{ $t('general.cancel') }}
          </b-button>
        </div>

      </b-form>
    </template>
  </b-sidebar>
</template>

<script>
import {
  BSidebar,
  BForm,
  BFormGroup,
  BFormInput,
  BFormInvalidFeedback,
  BButton,
  BMediaAside,
  BTab,
  BRow,
  BMediaBody, BCol, BTabs, BMedia, BFormCheckbox, BImg, BLink,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import ToastificationContent from '@core/components/toastification/ToastificationContent'
import { mapGetters } from 'vuex'
import StatusMixin from '@/mixins/StatusMixin'
import CategoryMixin from '@/mixins/CategoryMixin'
import InitFileManager from '@/components/InitFileManager.vue'
import Treeselect from '@riophae/vue-treeselect'

export default {
  components: {
    BLink,
    BImg,
    Treeselect,
    BFormCheckbox,
    BMedia,
    BTabs,
    BCol,
    BMediaBody,
    BRow,
    BTab,
    BMediaAside,
    InitFileManager,
    BSidebar,
    BForm,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BButton,
  },
  directives: {
    Ripple,
  },
  mixins: [CategoryMixin, StatusMixin],
  model: {
    prop: 'openedModalForm',
    event: 'update:opened-modal-form',
  },
  props: {
    openedModalForm: {
      type: Boolean,
      required: true,
    },
    item: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      languages: [],
      items: [],
    }
  },
  computed: {
    ...mapGetters({
      errors: 'validation/errors',
    }),
    siteUrl() {
      return process.env.VUE_APP_APP_URL || ''
    },
  },
  async beforeCreate() {
    await this.$http.get('/api/languages')
      .then(response => {
        this.languages = response.data.data
      })
  },
  destroyed() {
    this.$store.dispatch('validation/clearErrors')
  },
  mounted() {
    this.$http.get('/api/admin/navigation_items', {
      params: {
        navigation_id: this.$route.params.id,
      },
    })
      .then(response => {
        this.items = response.data
      })
  },
  methods: {
    hide() {
      this.$store.dispatch('validation/clearErrors')
      this.$emit('update:opened-modal-form', false)
    },
    forceUpdate() {
      this.$forceUpdate()
    },
    selectedAvatar(data) {
      this.item.avatar = data
      this.$forceUpdate()
    },
    onSubmit() {
      if (this.item.id === undefined) {
        this.$http.post('/api/admin/navigation_items', this.item)
          .then(() => {
            this.$emit('refetch-data')
            this.$emit('update:opened-modal-form', false)
            this.$store.dispatch('validation/clearErrors')
          })
          .catch(error => {
            this.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: error.response.data.message,
                icon: 'AlertCircleIcon',
                variant: 'warning',
              },
            })
          })
      } else {
        this.$http.put(`/api/admin/navigation_items/${this.item.id}`, this.item)
          .then(() => {
            this.$emit('refetch-data')
            this.$emit('update:opened-modal-form', false)
            this.$store.dispatch('validation/clearErrors')
          })
          .catch(error => {
            this.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: error.response.data.message,
                icon: 'AlertCircleIcon',
                variant: 'warning',
              },
            })
          })
      }
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';

#opened-modal-form {
  .vs__dropdown-menu {
    max-height: 200px !important;
  }
}
</style>
